import { createGlobalStyle } from 'styled-components'

import '@coreui/coreui/dist/css/coreui.min.css'

const GlobalStyle = createGlobalStyle`
    :root {
        --color-sidebar: #3E3E3E;
        --color-sidebar-hover: #5D5D5D;
        --color-background: #f8f8f8;
        --color-background-card: #ffffff;
        --color-background-title-bar: #f8f8f8;
        --color-background-footer: #f8f8f8;
        --color-background-input: #f3f5fe;
        --color-font: #1f1f1f;
        --color-icons: #ee1b2c;
        --color-white: #FFFFFF;
        --color-grey: #d3cede;
        --color-blue: #3E3E3E;
        --color-green: #82C20A;
        --color-orange: #C28700;
        --color-red: #F55859;
        --color-yellow: #D1D3D4;
    }
    * {
        box-sizing: border-box;
    }
    body {
        font-family: Arial, Helvetica, sans-serif;
        margin: 0;
		padding: 0;
        background-color: var(--color-background);
        color: var(--color-font);
        max-width: 100vw;
        overflow-x: hidden;
    }
    h1, h2, h3, h4, h5, h6, p {
        margin: 0;
    }
`
export default GlobalStyle
