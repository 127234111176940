import { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Sidebar, Menu, SidebarBrand, User, Name, Email } from './styled'
import { FaUsers, FaIndustry, FaChartArea, FaImages, FaLayerGroup } from 'react-icons/fa'
import { IoMdAnalytics } from 'react-icons/io'
import MenuItem from 'components/MenuItem'
import { Context } from 'contexts/context'
import { getUser } from 'services/auth'

const Component = () => {

  const { toggle, setToggle, user, setUser } = useContext(Context)

  useEffect(() => {
    (async () => {
      setUser(getUser().data)
    })()
  }, [])

  const { file_url, name, roles } = user

  return (
    <Sidebar
      className={`
        ${toggle ? 'open' : 'closed'}
        ${toggle ? 'menu-open' : 'menu-closed'}
      `}
    >
      <div>
        <Link to="/dashboard">
          <SidebarBrand className={toggle ? 'open' : 'closed'} >
            <img className="logo" src="/logo192-mono.png" alt="elleve" />
            <img className="logo-mini" src="/logo192-mono.png" alt="elleve" />          </SidebarBrand>
        </Link>
        <User to="/profile">
          <img className={`${toggle ? 'open' : 'closed'}`} src={file_url ? file_url : '/user.svg'} alt="analyticdbi" />
          <div className={`${toggle ? 'open' : 'closed'}`}>
            <Email>Bem vindo</Email>
            <Name>{name}</Name>
          </div>
        </User>
        <Menu>
          {roles === 'admin' ? <MenuItem onClick={() => setToggle(false)} path="/grupos" icon={<FaIndustry />} label="Relatórios" /> : <MenuItem path="/relatorios" icon={<FaChartArea />} label="Relatórios" />}
          {roles === 'admin' && <MenuItem onClick={() => setToggle(false)} path="/usuarios" icon={<FaUsers />} label="Usuários" />}
          {roles === 'admin' && <MenuItem onClick={() => setToggle(false)} path="/cards" icon={<FaImages />} label="Cards" />}
          {roles === 'admin' && <MenuItem onClick={() => setToggle(false)} path="/colecoes" icon={<FaLayerGroup />} label="Grupos" />}
          {roles === 'admin' && <MenuItem onClick={() => setToggle(false)} path="/analytics" icon={<IoMdAnalytics />} label="Analytics" />}
        </Menu>
      </div>
    </Sidebar>
  )
}

export default Component
