import React from 'react'
import ReactDOM from 'react-dom'
import App from 'main/App'
import 'antd/dist/antd.css';

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
)
