import { useContext } from 'react'

import { Context } from 'contexts/context'

import Topbar from 'components/Topbar'
import Sidebar from 'components/Sidebar'

import { Body, Content, ContentWrapper } from './styled'

const Base = ChildrenComponent => {
    const ComponentBase = props => {
        const { toggle } = useContext(Context)

        return (
            <Body>
                <Sidebar />
                <ContentWrapper>
                    <Topbar />
                    <Content toggle={toggle}>
                        <ChildrenComponent {...props} />
                    </Content>
                </ContentWrapper>
            </Body>
        )
    }

    return ComponentBase
}

export default Base
